export default{
    name: 'my-balance',
    data(){
        return{
            member_data: null,
            currentIndex: 0,
            nextMembershipLevels: [],
            currBenefitIndex: 0,
        }
    },
    created(){
        this.member_data = this.getMemberData;
        console.log(this.$store.state.auth.user)
        this.fetchConfig();
    },
    computed:{
        getMemberData(){
            if (this.$store.state.auth.user) {
                return this.$store.state.auth.user
            } else {
                return [];
            }
        },
    },
    methods: {
        incrCurIndex() {
            if((this.member_data.points.length - 1) < (this.currentIndex +1)) {
                this.currentIndex = 0;
            } else {
                this.currentIndex++;
            }
        },
        decrCurIndex() {
            if((this.currentIndex - 1) < 0) {
                this.currentIndex = this.member_data.points.length - 1;
            } else {
                this.currentIndex --;
            }
        },
        incrBenefitCurIndex() {
            if((this.nextMembershipLevels.length - 1) < (this.currBenefitIndex +1)) {
                // this.currBenefitIndex = 0;
            } else {
                this.currBenefitIndex++;
            }
        },
        decrBenefitCurIndex() {
            if((this.currBenefitIndex - 1) < 0) {
                // this.currBenefitIndex = this.nextMembershipLevels.length - 1;
            } else {
                this.currBenefitIndex --;
            }
        },
        async fetchConfig() {
            const resp = await this.$http.get('/config');
            let membershipLevels = resp.data.membership_level_list;
            membershipLevels.forEach(element => {
                if(element.membership_level_id > this.getMemberData.membership_level.id && !this._.isEmpty(element.membership_benefits)) {
                    this.nextMembershipLevels.push(element.membership_benefits);
                }
            });
            console.log(this.nextMembershipLevels);
        },
        async syncMember(){
            console.log('delete account');
            try {
                const resp = await this.$http.post('/sync-member-points')
                this.callGetMember(resp.data)
                // console.log('member before', this.member_data.points, resp.data)
                // this.member_data.points.forEach(itm => {
                //     itm.value = resp.data[itm.name]
                // })
                // this.$store.commit('auth/UPDATE_USER', this.member_data)
                // console.log('member after', this.member_data.points, resp.data)
            } catch (e) {
               console.log(e)
            }
        },
        async callGetMember(syncRsp) {
            try {
                const resp = await this.$http.post('/get-member-data')
                this.member_data = resp.data.response_data.member_detail
                console.log('member before', this.member_data.points)
                this.member_data.points.forEach(itm => {
                    console.log('sync data: ', syncRsp[itm.name])
                    itm.value = syncRsp[itm.name]
                })
                this.$store.commit('auth/UPDATE_USER', this.member_data)
                console.log('member after', this.member_data.points)
            } catch (e) {
               console.log(e)
            }
        },
    }
}
