import {
    BFormInvalidFeedback,
    BFormInput,
    BFormDatepicker,
} from 'bootstrap-vue'
import { validationMixin } from 'vuelidate'
import {
    required,
    email,
    numeric,
    sameAs,
    between,
} from 'vuelidate/lib/validators'
import verificationModal from '@/components/verification_modal/'

export default {
    mixins: [validationMixin],
    components: {
        BFormInvalidFeedback,
        BFormInput,
        BFormDatepicker,
        verificationModal,
    },
    data() {
        return {
            member_data: null,
            showModal: false,
            numberVerified: true,
            phone: null,
            cities: [],
            regions: [],
            genders: [],
            membershipLevels: [],
            termsCheck: false,
            showPassword: false,
            showOldPassword: false,
            showConfirmPassword: false,
            dobConstraint: new Date(),
            countries: [],
            occupation_arr: [],
            occupation_name: '',
            hasSpouseOrPartner: false,
            isExcludedFromCasino: false,
            form: {
                email: '',
                password: '',
                oldpassword: '',
                confirmPassword: '',
                phoneNumber: '',
                firstName: '',
                lastName: '',
                dob: null,
                gender: null,
                address: '',
                suburb: '',
                city: null,
                postCode: null,
                region: null,
                middlename: '',
                occupation: '',
                citizenship_country_id: 0,
            },
        }
    },
    validations: {
        form: {
            email: {
                required,
                email,
            },
            password: {},
            oldpassword: {},
            confirmPassword: {
                sameAsPassword: sameAs('password'),
            },
            phoneNumber: {
                required,
            },
            firstName: {
                required,
            },
            lastName: {
                required,
            },
            dob: {
                required,
            },
            gender: {
                required,
            },
            address: {
                required,
            },
            occupation: {
                required,
            },
            city: {
                required,
                between: between(1, 10000),
            },
            postCode: {
                required,
                numeric,
            },
            region: {
                required,
                between: between(1, 10000),
            },
            citizenship_country_id: {
                required,
                between: between(1, 10000),
            },
        },
    },
    mounted() {
        this.placeApi()
    },
    created() {
        this.dobConstraint.setYear(new Date().getFullYear() - 20)

        this.fetchConfig()
        this.member_data = this.getMemberData
        this.setData()
    },
    computed: {
        getMemberData() {
            if (this.$store.state.auth.user) {
                return this.$store.state.auth.user
            } else {
                return []
            }
        },
    },
    methods: {
        async fetchConfig() {
            try {
                const resp = await this.$http.get('/config')
                this.cities = resp.data.city_list
                this.regions = resp.data.region_list
                this.genders = resp.data.gender_list
                this.membershipLevels = resp.data.membership_level_list
                this.countries = [
                    {
                        id: 0,
                        country_name: 'Select Country of Citizenship',
                        disabled: true,
                    },
                    ...resp.data.country_list,
                ]
                this.occupation_arr = [
                    {
                        id: 0,
                        occupation_name: 'Select Occupation',
                        disabled: true,
                    },
                    ...resp.data.occupations,
                    {
                        id: 9,
                        occupation_name: 'Other',
                    },
                ]
            } catch (e) {
                console.error(e)
                this.$bvToast.toast('Oops! Something went wrong', {
                    title: `Error`,
                    variant: `danger`,
                    solid: true,
                })
            }
        },
        focusField(refName, event) {
            if (this.$refs[refName].$el.hasAttribute('disabled')) {
                event.target.innerHTML = 'Ok'
                this.$refs[refName].$el.removeAttribute('disabled')
                this.$refs[refName].$el.focus()
            } else {
                event.target.innerHTML = 'Edit'
                this.$refs[refName].$el.setAttribute('disabled', 'disabled')
            }
        },
        setData() {
            this.form.email = this.member_data.email
            this.form.phoneNumber = this.member_data.phone_number
            this.form.firstName = this.member_data.first_name
            this.form.middlename = this.member_data.middle_name
            this.form.lastName = this.member_data.last_name
            this.form.dob = this.member_data.dob
            this.form.address = this.member_data.address.street_address
            this.form.suburb = this.member_data.address.suburb
            this.form.postCode = this.member_data.address.postal_code
            this.form.gender = this.member_data.gender_id
            this.form.city = this.member_data.address.city_id
            this.form.region = this.member_data.address.region_id
            this.form.citizenship_country_id =
                this.member_data.citizenship_country_id
            this.occupation_name = this.member_data.occupation
            this.hasSpouseOrPartner =
                this.member_data.has_spouse_or_partner === 1
            this.isExcludedFromCasino =
                this.member_data.is_excluded_from_a_casino === 1
            this.form.occupation = 9
            let found_occ = this.occupation_arr.find(
                (item) => item.occupation_name === this.occupation_name
            )
            if (found_occ) {
                this.form.occupation = found_occ.id
            }
        },

        isNumVerified(value) {
            if (value) {
                this.numberVerified = true
            }
        },
        verifiyClosed(value) {
            if (value) {
                if (this.numberVerified) {
                    this.updatePhone()
                }
            }
        },

        validateState(name) {
            return this.$helper.validateState(name, this)
        },
        updatePhone() {
            this.phone = this.numberVerified
                ? this.form.phoneNumber
                : this.member_data.phone_number
            this.$http
                .post('/edit-my-profile', {
                    phone_number: this.phone,
                    type: 'W',
                    token: `WEBAPP-${this.moment().unix()}`,
                })
                .then((res) => {
                    // this.$helper.hideLoading();
                    if (res && res.status === false) {
                        // console.log('logged in')
                    }
                })
                .catch((err) => {
                    console.error('login error', err)
                })
        },
        onSubmit() {
            this.$v.form.$touch()
            if (this.$v.form.$anyError) {
                console.log(this.form)
                return
            }
            if (this.form.phoneNumber != this.member_data.phone_number) {
                this.numberVerified = false
                this.$bvModal.show('vmodal')
            }
            this.phone = this.numberVerified
                ? this.form.phoneNumber
                : this.member_data.phone_number
            this.$http
                .post('/edit-profile-web', {
                    ...(this.form.firstName != this.member_data.first_name && {
                        first_name: this.form.firstName,
                    }),
                    ...(this.form.middlename !=
                        this.member_data.middle_name && {
                        middle_name: this.form.middlename,
                    }),
                    ...(this.form.lastName != this.member_data.last_name && {
                        last_name: this.form.lastName,
                    }),
                    ...(this.form.email != this.member_data.email && {
                        email: this.form.email,
                    }),
                    ...(this.form.password && { password: this.form.password }),
                    ...(this.form.oldpassword && { oldpassword: this.form.oldpassword }),
                    ...(this.phone != this.member_data.phone_number && {
                        phone_number: this.phone,
                    }),
                    // country_code: 64,
                    ...(this.form.dob != this.member_data.dob && {
                        dob: this.form.dob,
                    }),
                    ...(this.form.gender != this.member_data.gender_id && {
                        gender_id: this.form.gender,
                    }),
                    ...(this.form.address !=
                        this.member_data.address.street_address && {
                        street_address: this.form.address,
                    }),
                    ...(this.form.suburb != this.member_data.address.suburb && {
                        suburb: this.form.suburb,
                    }),
                    ...(this.form.postCode !=
                        this.member_data.address.postal_code && {
                        postal_code: this.form.postCode,
                    }),
                    ...(this.form.region !=
                        this.member_data.address.region_id && {
                        region_id: this.form.region,
                    }),
                    ...(this.form.city != this.member_data.address.city_id && {
                        city_id: this.form.city,
                    }),
                    ...(this.occupation_name != this.member_data.occupation && {
                        occupation: this.occupation_name,
                    }),
                    ...(this.form.citizenship_country_id !=
                        this.member_data.citizenship_country_id && {
                        citizenship_country_id:
                            this.form.citizenship_country_id,
                    }),
                    ...(this.hasSpouseOrPartner !=
                        !!this.member_data.has_spouse_or_partner && {
                        has_spouse_or_partner: this.hasSpouseOrPartner ? 1 : 0,
                    }),
                    ...(this.isExcludedFromCasino !=
                        !!this.member_data.is_excluded_from_a_casino && {
                        is_excluded_from_a_casino: this.isExcludedFromCasino
                            ? 1
                            : 0,
                    }),
                })
                .then((res) => {
                    // this.$helper.hideLoading();
                    if (res && res.status === 200) {
                        this.$helper.showMessage(res.data.message)
                        if (this.form.password && this.form.oldpassword) {
                            this.$store
                            .dispatch('auth/logout')
                            .then(() => {
                                this.$router.push({ path: '/' })
                            })
                            .catch((err) => {
                                console.error(err)
                            })
                        }
                    }
                })
                .catch((err) => {
                    console.error('login error', err)
                })
        },
        placeApi() {
            let script = document.createElement('script')
            let vm = this
            script.setAttribute(
                'src',
                'https://api.addressfinder.io/assets/v3/widget.js'
            )
            script.setAttribute('async', true)
            script.onload = () => {
                this.widget = new window.AddressFinder.Widget(
                    document.getElementById('address'),
                    'FUHT89L634EGXQJVPMNA',
                    'NZ',
                    {
                        address_params: {},
                        empty_content:
                            'No address found. This could be a new address, or you may need to check the spelling',
                        show_locations: true,
                    }
                )
                this.widget.on(
                    'result:select',
                    function (fullAddress, metaData) {
                        const selected =
                            new window.AddressFinder.NZSelectedAddress(
                                fullAddress,
                                metaData
                            )
                        const add_obj = {
                            address:
                                selected.address_line_1() +
                                (selected.address_line_2()
                                    ? ', ' + selected.address_line_2()
                                    : ''),
                            suburb: selected.suburb() || '',
                            city: selected.city() || '',
                            postcode: selected.postcode() || '',
                            region: metaData.region,
                        }

                        vm.form.address = add_obj.address
                        const select_city = vm.cities.find((obj) => {
                            return (
                                obj.city_name.toLowerCase() ===
                                add_obj.city.toLowerCase()
                            )
                        })

                        vm.form.city = select_city ? select_city.city_id : 0

                        const select_region = vm.regions.find((obj) => {
                            return (
                                obj.region_name.toLowerCase() ===
                                add_obj.region.toLowerCase()
                            )
                        })
                        vm.form.region = select_region
                            ? select_region.region_id
                            : 0

                        vm.form.address = add_obj.address
                        document.getElementById('address').value =
                            add_obj.address
                        vm.form.suburb = add_obj.suburb
                        vm.form.postCode = add_obj.postcode
                    }
                )
            }
            document.head.appendChild(script)
        },
        occupationSelected(item) {
            if (item === 9) {
                this.occupation_name = ''
                this.$refs['my-modal'].show()
            } else {
                this.occupation_name = this.occupation_arr.find(
                    (occ) => occ.id === item
                ).occupation_name
            }
        },
        hideModal() {
            if (this.occupation_name.trim().length === 0) {
                this.form.occupation = 0
            }
            this.$refs['my-modal'].hide()
        },
    },
}
