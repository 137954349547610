import myBalance from "./MyBalance"
import myDetails from "./MyDetails"
import { BButton } from 'bootstrap-vue'
export default {
    components:{
        myBalance,
        myDetails,
        BButton
    },
    data(){
        return {
            selectedTab1: true,
            selectedTab2: false,
            deletedAcc: '',
            dismissSecs: 3,
            dismissCountDown: 0,
            displayModal: false,
        }
    },
    created(){
    },
    methods:{
        changeTab(val){
            if(val == 1){
                this.selectedTab1 =  true;
                this.selectedTab2 =false;
            }
            if(val == 2){
                this.selectedTab1 =  false;
                this.selectedTab2 =true;
            }
        },
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        async deleteAccount() {
            this.displayModal = false
            console.log('delete account')
            try {
                const resp = await this.$http.post('/delete-account-request')
                this.deletedAcc = resp.data.message
                this.dismissCountDown = this.dismissSecs 
                console.log(resp.data.message)
            } catch (e) {
               console.error(e)
            }
        },
        triggerDelete() {
            this.$refs.delete_modal.show();
        },
        hideModal() {
            this.displayModal = false
        },
    },
}
